@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200-700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #002917b0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
